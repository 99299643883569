.calendar-container .react-calendar {
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0.5rem;
  background: white;
}

.calendar-container .react-calendar__tile {
  padding: 0.75em 0.5em;
  text-align: center;
  color: black;
}

.calendar-container .react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.calendar-container .react-calendar__tile--now {
  background: #E8EDFF;
}

.calendar-container .react-calendar__tile--active,
.calendar-container .react-calendar__tile--range {
  background: #0000FF !important;
  color: white !important;
}

.calendar-container .react-calendar__tile--rangeStart,
.calendar-container .react-calendar__tile--rangeEnd {
  background: #0000FF !important;
  color: white !important;
}

.calendar-container .react-calendar__tile--rangeBetween {
  background: #E8EDFF !important;
  color: black !important;
}

.calendar-container .react-calendar__tile:enabled:hover {
  background-color: #e6e6e6;
}

.calendar-container .react-calendar__tile--active:enabled:hover {
  background: #0000dd !important;
}

.calendar-container .react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.calendar-container .react-calendar__navigation button:enabled:hover,
.calendar-container .react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
} 